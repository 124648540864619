.ModalAuthBody {
  padding: 20px;
  background-color: #F8F8F8;
  border-radius: 5px;
}

.NavTabs {

  color: #2c1e05 !important;
}

.NavTabs .nav-link {
  color: #2c1e05 !important;
  font-weight: 700;
}


.tab-content {
  background-color: #F8F8F8;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  color: #e29a10 !important;
  font-weight: 900;

}

.form-input {
  background-color: #F8F8F8;
  border: 1px solid #2c1e05;
  color: #2c1e05;
  padding: 10px;
}

.form-input::placeholder {
  color: #2c1e05;
}

.form-button {
  background-color: #e29a10;
  color: #2c1e05;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.form-button:hover {
  background-color: #2c1e05;
  color: #F8F8F8;
}
