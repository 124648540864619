.adminContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  width: 100%;
  max-height: 100vh;
  justify-content: flex-start;
  overflow: auto;
}



.adminHeader {
  margin-top: 20px;
  font-weight: 900;
  font-size: x-large;
  text-transform: uppercase;
  color: #e29a10;
}

.adminSearch {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.adminSecondRow {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.adminThirdRow {
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.adminSearchInput {
  padding: 8px;
  display: flex;
  max-width: 300px;
  height: 40px;
}

.button-header-order {
  color: rgba(0, 0, 0, 1);
  background: #e29a10;
  font-weight: 800;
  font-size: small;
  border: none;
  outline: none;
  width: 180px;
  height: 40px;
}

.button-header-order:hover {
  color: rgba(0, 0, 0, 1);
  box-shadow: none;
  font-weight: 800;
  background: #ffad14;
  outline: none;
  border: none;
}

.button-header-order:focus {
  color: rgba(0, 0, 0, 1);
  font-weight: 800;
  box-shadow: none;
  background: #e29a10;
  outline: none;
  border: none;
}

.orderListContainer-admin {
}

.orderListContainer-admin-history {
  width: 80%;
}
.orders-pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-items: baseline;
  margin-top: 5vh;
}
.order-list-pagination {
  display: flex;
  flex-direction: column-reverse;
  width: 95%;
}

.orderList-admin {
  margin: 30px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(89vh - 380px);
  overflow-y: auto;
  box-shadow: 0px 0px 5px #434343;
  border-radius: 5px;
  justify-content: space-between;
  min-height: 150px;
}

tr , th {
  font-weight: 600;
  font-size: x-small;
  text-align: center;
}

.orderList-admin-history {
  max-height: calc(89vh - 290px) !important;
}

.headrow-orderList-admin {
  width: 80%;
  background-color: #2c1e05;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  flex-direction: row;
  position: fixed;
  margin-bottom: 10px;
}

.head-column-border {
  border-right: 1px solid #F8F8F8;
  padding: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: small;
  color: #F8F8F8;
  font-weight: 900;
}

.row-orderList-admin {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.246);
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  flex-direction: row;
}

.row-orderList-admin>div {
  border-left: #434343;
}
.table-striped>tbody>tr:nth-child(even)>td{
  background-color: #FAEBCD;
}
.table > tbody > tr > td, .table > thead > tr > th{
  vertical-align: middle;
}



th {
  border-right: 1px solid #f8f8f8;
  color: #f8f8f8 !important;
  font-size: small;
  font-weight: 900;
  text-align: center;
  align-items: center;
  background-color: #2c1e05 !important;
}

.table-striped>tbody>tr:nth-child(odd)>td{

  background-color: #F7C873;
}

.table-striped>tbody>tr {
  position: relative;
}

.table-striped>thead>tr>th {
  position: sticky;
  top: 0;
  z-index: 10;
}

td {
  /* position: absolute; */
  min-height: 100%;
  /* display: flex; */
}

.table-responsive {
  max-height: 50vh !important;
}

.column-border {
  border-right: 1px solid #F8F8F8;
  padding: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: x-small;
}

.button-export-column-border {
  cursor: pointer;
  display: flex !important;
  flex-direction: column !important;
  min-height: 77px !important;
  vertical-align: middle !important;
}

.button-export-column-border :hover {
  color: #e29a10;
}

.small-btn-column {
  font-size: x-small !important;
  height: 30px !important;
  width: 20px !important;
  background-color: #694708 !important;
  margin: 0px !important;
  padding: 0px !important;
}

.small-btn-column:hover {
  background: #e29a10 !important;
}

.button-status-order {
  color: rgba(0, 0, 0, 0.707);
  background: #F7C873;
  font-weight: 800;
  font-size: medium;
  border: none;
  outline: none;
  width: 100px;
}

.button-status-order:hover {
  color: rgba(0, 0, 0, 1);
  box-shadow: none;
  font-weight: 800;
  background: #e29a10;
  outline: none;
  border: none;
}

.button-status-order:focus {
  font-weight: 800;
  color: white;
  box-shadow: none;
  background: #e29a10;
  outline: none;
  border: none;
}

.buttons-container {
    display: flex;
    justify-content: space-between;
    width: 800px;
    max-width: 800px;
    margin-top: 20px;
}
