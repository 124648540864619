.toggle-switch {
  width: 50px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slider {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  transition: left 0.3s;
}

.toggle-switch.on {
  background-color: #e29a10;
}

.toggle-switch.off {
  background-color: #ccc;
}

.toggle-switch.on .slider {
  left: 30px;
}
