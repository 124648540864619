/* Importation de la police Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

/* Réinitialisation de base */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

/* HEADER */
.navbar {
  background-color: rgba(0, 0, 0, 1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 50px;
  z-index: 9999;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 40px;
}

.logo img {
  height: 125px;
  transition: height 0.3s ease;
}

.navbar.scrolled .logo img {
  height: 100px;
}

.nav-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 40px;
}

.nav-right ul {
  list-style: none;
  display: flex;
  gap: 40px;
}

.nav-right ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
  transition: all 0.3s;
}

.nav-right ul li a:hover {
  background: #ffa500;
  color: #000;
}

/* BOUTON LOGIN */
.btn-login {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-login:hover {
  background: #fff;
  color: #000;
}

/* NAVIGATION MOBILE */
.nav-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 10000;
}

/* RESPONSIVE NAVIGATION */
@media (max-width: 768px) {
  .nav-toggle {
    display: block;
  }
  .nav-right {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #000;
    position: absolute;
    top: 100%;
    left: 0;
    text-align: center;
    padding: 20px 0;
  }
  .nav-right.active {
    display: flex;
  }
  .nav-right ul {
    flex-direction: column;
    gap: 15px;
  }
  .btn-login {
    width: 80%;
    text-align: center;
  }
}

/* RESPONSIVE NAVBAR FOR TABLETS */
@media (max-width: 1024px) {
  .navbar {
    padding: 15px 20px;
  }
  .nav-right ul {
    gap: 20px;
  }
}

/* MAIN CONTENT */
html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
}

main {
  flex: 1;
  padding: 180px 40px 60px;
  text-align: center;
}

.intro {
  margin-bottom: 60px;
}

.intro h1 {
  font-size: 3rem;
  font-weight: 700;
  color: #222;
}

/* SECTION À PROPOS */
#a-propos {
  background: #fff;
  padding: 80px 20px;
  text-align: center;
  color: #333;
  border-top: 3px solid #ddd;
}

#a-propos h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 30px;
}

.a-propos-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

.a-propos-text {
  flex: 1;
  min-width: 300px;
  text-align: justify;
}

.a-propos-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
}

.a-propos-images {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.a-propos-images img {
  width: 48%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* SECTION INFORMATIONS */
.info-section {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 50px auto;
  max-width: 1200px;
}

.info-box {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}

.info-box h3 {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.info-box p {
  font-size: 1rem;
  line-height: 1.4;
}

/* NOS SERVICES */
#nos-services {
  background: #f8f9fa;
  padding: 80px 20px;
  text-align: center;
  color: #333;
  border-top: 3px solid #ddd;
}

#nos-services h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 30px;
}

.services-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.service-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: calc(100% / 5 - 20px);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.service-card img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.service-card h3 {
  font-size: 1.4rem;
  margin-top: 15px;
  font-weight: 600;
  color: #333;
}

#pourquoi-choisir {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

#pourquoi-choisir h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

#pourquoi-choisir ul {
  list-style-type: none;
  padding: 0;
}

#pourquoi-choisir ul li {
  font-size: 18px;
  margin: 10px 0;
}

.checkmark {
  color: #e29e08;
}

/* PARTNERS */
.partners {
  margin-top: 50px;
  text-align: center;
}

.partners-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.partner-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 180px;
  height: 100px;
  padding: 10px;
}

.partner-card img {
  max-width: 100%;
  max-height: 80px;
  object-fit: contain;
}

/************************************************************
  STYLES POUR LA PAGE CARRIÈRES
*************************************************************/

/* Conteneur principal */
.carrieres-container {
  max-width: 1200px;
  margin: 120px auto;
  padding: 20px;
  text-align: center;
}

/* Titre principal */
.carrieres-container .page-title {
  font-size: 2.8rem;
  font-weight: bold;
  color: #222;
  margin-bottom: 30px;
}

/* Section présentation */
.about-jobs {
  background: linear-gradient(to right, #f7f7f7, #eaeaea);
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 35px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.about-jobs h2 {
  font-size: 2.2rem;
  color: #222;
}

.about-jobs p {
  font-size: 1.2rem;
  color: #444;
  margin-top: 10px;
}

/************************************************************
  1) LISTE DES OFFRES D'EMPLOI
*************************************************************/

.job-listings {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 25px;
  margin-top: 30px;
}

.job-item {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-item img {
  width: 100%;
  max-width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
  display: block;
}

.job-item h3 {
  font-size: 1.6rem;
  color: #222;
  margin-bottom: 10px;
}

.job-item p {
  font-size: 1.1rem;
  color: #555;
  text-align: center;
  margin-bottom: 15px;
}

.job-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/************************************************************
  3) CANDIDATURE SPONTANÉE
*************************************************************/

.job-other {
  background: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  margin-top: 50px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.job-other h2 {
  font-size: 2rem;
  color: #222;
  margin-bottom: 15px;
}

.job-other p {
  font-size: 1.2rem;
  color: #555;
}

.job-other a {
  font-weight: bold;
  color: #e29e08;
  text-decoration: none;
}

.job-other a:hover {
  text-decoration: underline;
}

/* FOOTER */
.footer-dark {
  width: 100%;
  background: #000;
  color: #fff;
  padding: 30px 0;
  text-align: center;
  font-size: 0.9rem;
  position: relative;
  bottom: 0;
}

.footer-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-bottom: 10px;
}

.footer-info p {
  margin: 0;
}

.footer-info a {
  color: #e29e08;
  text-decoration: none;
  font-weight: 600;
}

.footer-separator {
  width: 90%;
  height: 1px;
  background: #fff;
  opacity: 0.3;
  margin: 15px auto;
}

.footer-copyright {
  font-size: 0.8rem;
}

/* RESPONSIVE DESIGN FOR TABLETS */
@media (max-width: 1024px) {
  .services-grid {
    flex-wrap: wrap;
    justify-content: center;
  }
  .service-card {
    width: 45%;
  }
}

/* RESPONSIVE DESIGN FOR MOBILE */
@media (max-width: 768px) {
  .nav-right {
    flex-direction: column;
    gap: 15px;
  }
  .partners-grid,
  .services-grid {
    flex-direction: column;
    align-items: center;
  }
  .partner-card,
  .service-card {
    width: 90%;
  }
  main {
    padding: 140px 20px 40px;
  }
  .a-propos-content {
    flex-direction: column;
    text-align: center;
  }
  .a-propos-text {
    text-align: center;
  }
  .a-propos-images img {
    width: 80%;
  }
  .info-section {
    flex-direction: column;
    gap: 20px;
  }
  .info-box {
    width: 100%;
    margin: 0 auto;
  }
}

/* EXTRA SMALL SCREENS */
@media (max-width: 480px) {
  main {
    padding: 100px 10px 30px;
  }
  .intro h1 {
    font-size: 2.5rem;
  }
  .intro p {
    font-size: 1rem;
  }
  .info-box {
    padding: 15px;
    width: 100%;
  }
  #nos-services h2,
  #a-propos h2 {
    font-size: 2rem;
  }
  .service-card,
  .partner-card {
    width: 100%;
  }
}

footer.footer {
  width: 100vw;  /* Ensures the footer spans the entire viewport width */
  margin: 0;
  padding: 0;
  background-color: black;  /* Matches your desired background */
    color: white;  /* Matches your desired text color */
  padding-top: 10px;  /* Pushes the footer to the bottom of the page */
}
