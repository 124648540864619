@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&display=swap');

.app {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  max-width: 100vw !important;
  overflow-x: hidden;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.summary-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.invoice-card {
  border: 1px solid #ddd;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
  padding: 15px;
  background: #fff;
}

.invoice-header {
  background: #e29a10 ;
  color: black;
  font-weight: 900;
  padding: 15px;
  border-radius: 5px 5px 0 0;
}

.section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
}

.info-table td {
  padding: 5px;
}

/* Order Page Main Layout */
.order-main-container {
  display: flex;
  gap: 10px; /* Space between Order and Preview */
  padding: 20px;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  align-items: flex-start;
}

/* Order container styling */
.orderContainer {
  flex: 2; /* Takes up more space */
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 85vh;
  overflow: hidden;
}

/* Preview container styling */
.order-preview-container {
  flex: 1; /* Takes up less space */
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 120px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 85vh;
  min-width: 350px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .order-main-container {
    flex-direction: column; /* Stacks the order form and preview vertically on smaller screens */
  }

  .orderContainer,
  .order-preview-container {
    max-width: 100%; /* Full width on smaller screens */
  }
}


@media only screen and (max-width: 900px) {
  .app {
    margin-top: 90px;
  }
}
