.orderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  width: 100%;
  max-height: calc(100vh - 220px);
  justify-content: flex-start;
  overflow: auto;
  overflow: visible;

}

.tab-order {
  display: flex;
  width: 100%;
  height: 100%;
}


.orderTabContainer {
  width: 100%;
  height: 99%;
  max-width: 800px;
  display: flex;
  border: none;
}


.content-order {
  display: flex;
  flex-direction: row;
  color: black;
}

.tabcontent-order {
  justify-content: center;
  padding: 20px 10px 20px 10px;
  background-color: #F8F8F8;
  box-shadow: 0px 0px 5px #434343;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  align-items: center;
  min-height: 650px;
  position: relative;
  margin-bottom: 10px;
}

.container-content-order {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  padding-top: 20px;
}

.container-content-order>div {
  padding-bottom: 5px;
}

/* tab button */
.navitem-order {
  background-color: #FAEBCD;
  box-shadow: 0px 0px 5px #434343;
  display: flex;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}


.navitem-order-active {
  background: #F7C873;
}

.button-tab-order:not(:hover) {
  border: none;
}

.button-tab-order {
  color: rgba(0, 0, 0, 0.707);
  font-weight: 500;
  font-size: medium;
  border: none;
  outline: none;
}

.button-tab-order:hover {
  color: rgba(0, 0, 0, 0.707);
  font-weight: 800;
  border: none;
  box-shadow: none;
  background: #e29a10;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
}


.button-tab-order:focus {
  box-shadow: none;
  background: gray;
  outline: none;
  border: none;

}

.button-tab-order-active:not(:hover) {
  border: none;
}

.button-tab-order-active {
  color: rgba(0, 0, 0, 0.707);
  background: #F7C873;
  font-weight: 800;
  font-size: medium;
  border: none;
  outline: none;
}

.button-tab-order-active:hover {
  color: rgba(0, 0, 0, 1);
  box-shadow: none;
  font-weight: 800;
  background: #e29a10;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
  border: none;
}

.button-tab-order-active:focus {
  font-weight: 800;
  color: white;
  box-shadow: none;
  background: gray;
  outline: none;
  border: none;
}


.text-bold-yellow {
  font-weight: 800;
  color: #e29a10;
}

.button-bottom-order {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  right: 0;
}

.button-bottom-order>button {
  margin: 10px;
}

.button-bottom-order-active {
  color: rgba(0, 0, 0, 0.707);
  background: #F7C873;
  font-weight: 800;
  font-size: medium;
  border: none;
  outline: none;
  width: 100px;
}

.button-bottom-order-active:hover {
  color: rgba(0, 0, 0, 1);
  box-shadow: none;
  font-weight: 800;
  background: #e29a10;
  outline: none;
  border: none;
}

.button-bottom-order-active:focus {
  font-weight: 800;
  color: white;
  box-shadow: none;
  background: #e29a10;
  outline: none;
  border: none;
}

.error-message {
  color: #e25a10;
  font-weight: 800;
}

.arrowcopyvalues {
  margin: -18px 0px -8px 0px;
  padding: 0;
  color: #e29a10;
  cursor: pointer;
}

.arrowcopyvalues:hover {
  color: #e25a10;
}
