.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  z-index: 999;
  max-width: 100vw;
}

.header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #e21b24; */

}

.header>div {
  margin: 10px;
}

.titleheader {
  font-weight: bold;
  font-size: x-large;
  font-weight: bold;
  position: relative;

}

.titleheader>img {
  width: 50px;
}

.navbarmenu {
  justify-content: flex-end;

}

.navbarhidden {
  display: none;
}

.navbar-nav>li {
  padding-left: 8px;
}

.nav-link-header {
  font-size: medium;
  font-weight: 900;
  border-radius: 10px 0px 10px 0px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.nav-icon {
  margin-right: 5px;
}



.nav-link-header:hover {
  background-color: #e2981051;
  border-radius: 10px 0px 10px 0px;
}

.nav-link-header-active {
  /* color: #e29a10 !important; */
  color: #e29a10 !important;
  text-decoration: underline;
}


.buttonHeaderContainer {
  display: flex;
}

.buttonHeaderContainer>div {
  margin-right: 10px;
}

.button-header>div {
  align-self: center;
}
